<template>
  <div class="news-page">
    <zv-nav-bar :title="newName"></zv-nav-bar>
    <div class="news-list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="load_more">
        <ul v-for="(item, index) in newsList" :key="index" @click="toNewsDetail(item)">
          <li>
            <h5>{{ item.title }}</h5>
            <div>
              <span>{{ item.publishDate }}</span>
              <div v-if="item.topFlag != '0'">置顶</div>
            </div>
          </li>
          <li>
            <div class="img-box">
              <img :src="item.img || defaultimg" :alt="item.title" />
            </div>
          </li>
        </ul>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      newsList: [], // 资讯列表
      defaultimg: '/images/index/information.png',
      nextPageUrl: 'index.json',
      newName: '',
      geturl: '',
    }
  },
  created() {
    this.setShare()
  },
  methods: {
    // 分享
    setShare() {
      const shareInfo = {
        title: `新闻资讯`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    load_more: function() {
      this.onLoad()
    },
    onLoad() {
      this.geturl = this.$route.query.name ? `/json/tzgg/${this.nextPageUrl}` : `/json/xwzx/${this.nextPageUrl}`
      let _this = this
      this.$axios({
        url: _this.geturl,
        methods: 'GET',
        params: {},
      }).then((res) => {
        if (res.status == 200) {
          // 加载状态结束
          _this.newName = res.data.name
          _this.loading = false
          _this.newsList = this.newsList.concat(res.data.data) //追加数据
          if (!res.data.isMore) {
            //数据全部加载完成
            _this.finished = true
          } else {
            _this.finished = false
          }
          _this.nextPageUrl = res.data.nextPageUrl
        }
      })
    },
    // 跳转至新闻资讯详情
    toNewsDetail(item) {
      if (item.artUrl.indexOf('.json') >= 0) {
        let artUrl = item.artUrl.substring(item.artUrl.search(/json/))
        this.$router.push({
          name: 'newsDetail',
          params: {
            url: artUrl,
          },
          query: {
            itemtype: (this.geturl = this.$route.query.name ? '通知' : ''),
          },
        })
      } else {
        window.location.href = item.artUrl
      }
    },
  },
}
</script>
<style lang="less" scoped>
.news-page {
  .news-list {
    // padding-top: 1.12rem;
    ul {
      display: flex;
      padding: 0.53rem 0.47rem 0 0.47rem;
      li {
        &:nth-child(1) {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-top: 0.25rem;
          h5 {
            margin: 0;
            color: #333333;
            font-size: 0.45rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 1rem;
            span {
              font-size: 0.32rem;
              color: #666;
            }
            div {
              background-color: #e7f2ff;
              border-radius: 0.13rem;
              font-size: 0.32rem;
              color: #1578f2;
              padding: 0.1rem;
            }
          }
        }
        &:nth-child(2) {
          .img-box {
            width: 2.88rem;
            height: 2.77rem;
            border-radius: 0.13rem;
            overflow: hidden;
            margin-left: 0.28rem;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              font-size: 0.37rem;
            }
          }
        }
      }
    }
  }
  .van-list__finished-text {
    font-size: 0.35rem;
    line-height: 1rem;
  }
  .van-loading__text {
    font-size: 0.35rem;
  }
}
</style>
